<template lang="html">
    <simple-page-layout padded>
        <portal to="title">
            Search
        </portal>

        <form @submit.stop>
            <ds-input-field
                v-model="searchTerm"
                placeholder="Search for hackathons, projects, and users"
                type="search"
            />

            <DsIconButton name="search" />
        </form>

        <p v-if="noResults">
            No results
        </p>

        <div class="search-results">
            <div v-if="filteredProjects.length">
                <h2>Projects</h2>

                <projects-list
                    v-if="filteredProjects.length"
                    :projects="filteredProjects"
                    @selected="(project) => $router.push({ name: 'project', params: { id: project.id} })"
                />
            </div>

            <div v-if="filteredHackathons.length">
                <h2>Hackathons</h2>

                <section class="card-grid">
                    <hackathon-card
                        v-for="(hackathon, i) in filteredHackathons"
                        :key="i"
                        :hackathon="hackathon"
                    />
                </section>
            </div>

            <div v-if="filteredUsers.length">
                <h2>Users</h2>

                <DsListItem
                    v-for="user in filteredUsers"
                    :key="user.uid"
                    clickable
                    border-style="full"
                    @click="$router.push({ name: 'user', params: { id: user.uid } })"
                >
                    <template #leading>
                        <ds-avatar
                            :size="40"
                            :name="user.name"
                            :email="user.email"
                            :image-url="user.photoUrl"
                        />
                    </template>

                    <template #title>
                        <p>
                            <DsChip v-if="guestsList.includes(user.email)" class="outline">
                                Guest
                            </DsChip>

                            {{ user.name }}
                        </p>
                    </template>

                    <template #trailing>
                        <DsIcon name="chevron-right" />
                    </template>
                </DsListItem>
            </div>
        </div>
    </simple-page-layout>
</template>

<script>
import HackathonCard from '@/components/HackathonCard';
import SimplePageLayout from '@/components/SimplePageLayout';
import ProjectsList from '@/components/ProjectsList';
import { mapState, mapGetters } from 'vuex';
import { roles } from '@/components/project/constants';

export default {
    components: {
        SimplePageLayout,
        HackathonCard,
        ProjectsList,
    },

    data() {
        return {
            searchTerm: '',
        };
    },

    computed: {
        trimmedSearchTerm() {
            return this.searchTerm.toLowerCase().trim();
        },

        noResults() {
            const resultCount = this.filteredHackathons.length + this.filteredProjects.length + this.filteredUsers.length;

            return resultCount === 0 && this.trimmedSearchTerm?.length > 0;
        },

        filteredHackathons() {
            if (!this.trimmedSearchTerm?.length) return [];

            return this.hackathons.filter(({ name }) => name.toLowerCase().includes(this.trimmedSearchTerm));
        },

        filteredProjects() {
            if (!this.trimmedSearchTerm?.length) return [];

            return this.projects.filter((project) => {
                const hasProjectTag = project.tags && project.tags.some((id) => this.getTag(id).name && this.getTag(id).name.toLowerCase().includes(this.trimmedSearchTerm));
                const hasProjectRole = project.rolesWanted && project.rolesWanted.some((role) => roles[role].toLowerCase().includes(this.trimmedSearchTerm));

                return project.name.toLowerCase().includes(this.trimmedSearchTerm) || hasProjectTag || hasProjectRole;
            });
        },

        filteredUsers() {
            return this.trimmedSearchTerm && this.users.filter(({ name }) => name.toLowerCase().includes(this.trimmedSearchTerm));
        },

        ...mapState({
            hackathons: ({ hackathons }) => hackathons.hackathons,
            projects: ({ projects }) => projects.projects,
            users: ({ users }) => users.users,
        }),

        ...mapGetters({
            getTag: 'tags/getTag',
            getAwardByProject: 'projects/getAwardByProject',
            guestsList: 'users/guestsList',
        }),
    },
};
</script>

<style lang="scss" scoped>
form {
    width: 500px;
    margin: $spacing-600 auto 0;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: $spacing-200;

    --input-margin-bottom: 0;

    @media($small) {
        width: 100%;
        margin: 0 0 $spacing-200;
    }
}

h2 {
    @media($small) {
        margin-bottom: $spacing-200;
    }
}

.card-grid {
    display: grid;
    grid-template-columns: repeat(4, calc(calc(100% - #{$spacing-600}) / 4));
    grid-gap: $spacing-200;

    @media($medium-down) { grid-template-columns: repeat(3, calc(calc(100% - #{$spacing-400}) / 3)); }
    @media($small) { grid-template-columns: repeat(2, calc(calc(100% - #{$spacing-200}) / 2)); }
    @media($extra-small) { grid-template-columns: 100%; }
}
</style>
