<template lang="html">
    <div class="simple-page-layout">
        <section :class="['scroll-wrap', { padded }]">
            <slot />
        </section>
    </div>
</template>

<script>
export default {
    props: {
        padded: Boolean,
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.simple-page-layout {
    margin: $spacing-200;
    overflow: hidden;
    background: $color-white;
    border-radius: $border-radius;
}

.scroll-wrap {
    height: calc(100vh - 120px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    @include custom-scroll-bar;

    &.padded {
        padding: $spacing-200;
    }
}
</style>
