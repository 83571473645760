<template>
    <div :class="['team-members', { stacked }]">
        <div
            v-for="user in teamMembers"
            :key="user"
            :class="['team-member', { 'team-lead': user === project.teamLead }]"
        >
            <DsIcon
                v-if="user === project.teamLead"
                name="flag-fill"
                title="Team lead"
                class="team-lead-indicator"
            />

            <div>
                <DsTooltip position="top" :disabled="stacked">
                    <template #reference>
                        <router-link :to="{ name: 'user', params: { id: getUser(user).id } }">
                            <ds-avatar
                                :title="getUser(user).name"
                                :size="avatarSize"
                                :image-url="getUser(user).photoUrl"
                                class="user-avatar"
                            />
                        </router-link>
                    </template>

                    <template #default>
                        {{ getUser(user).name }}
                    </template>
                </DsTooltip>
            </div>

            <span v-if="stacked">{{ getUser(user).name }}</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        project: Object,
        stacked: Boolean,
        compact: Boolean,
    },

    computed: {
        ...mapGetters({
            getUser: 'users/getUser',
        }),

        avatarSize() {
            return this.compact ? 36 : 44;
        },

        teamMembers() {
            return [...new Set([this.project.teamLead, ...this.project.members])]?.filter((member) => member);
        },
    },
};
</script>

<style lang="scss" scoped>
.team-members {
    margin-top: $spacing-200;
    padding-left: $spacing-100;
    display: flex;

    &.stacked {
        flex-direction: column;
        grid-gap: $spacing-100;
        margin: $spacing-200 0 0;

        .user-avatar {
            margin: 0;
        }

        .team-member {
            align-items: center;
            grid-gap: $spacing-200;
        }
    }
}

.team-member {
    display: flex;
}

.team-lead {
    position: relative;
}

.team-lead-indicator {
    --icon-color: #{$color-gray-700};
    --icon-size: 14px;
    padding: 2px;
    position: absolute;
    z-index: 2;
    left: -4px;
    top: -4px;
    border: 1px solid $color-gray-500;
    background-color: $color-white;
    border-radius: $border-radius;
}

.user-avatar {
    margin-left: -$spacing-100;
}
</style>
